module.exports={
  "name": "vega-lite",
  "author": "Jeffrey Heer, Dominik Moritz, Kanit \"Ham\" Wongsuphasawat",
  "version": "2.0.0",
  "collaborators": [
    "Kanit Wongsuphasawat <kanitw@gmail.com> (http://kanitw.yellowpigz.com)",
    "Dominik Moritz <domoritz@cs.washington.edu> (https://www.domoritz.de)",
    "Jeffrey Heer <jheer@uw.edu> (http://jheer.org)"
  ],
  "homepage": "https://vega.github.io/vega-lite/",
  "description": "Vega-lite provides a higher-level grammar for visual analysis, comparable to ggplot or Tableau, that generates complete Vega specifications.",
  "main": "build/src/index.js",
  "types": "typings/vega-lite.d.ts",
  "bin": {
    "vl2png": "./bin/vl2png",
    "vl2svg": "./bin/vl2svg",
    "vl2vg": "./bin/vl2vg"
  },
  "directories": {
    "test": "test"
  },
  "scripts": {
    "pretsc": "mkdir -p build && rm -rf build/*/** && cp package.json build/",
    "tsc": "tsc",
    "prebuild": "mkdir -p build/site build/test-gallery",
    "build": "npm run build:only",
    "build:only": "npm run tsc && cp package.json build && browserify src/index.ts -p tsify -d -s vl | exorcist build/vega-lite.js.map > build/vega-lite.js",
    "postbuild": "node node_modules/uglify-js/bin/uglifyjs build/vega-lite.js -cm --source-map content=build/vega-lite.js.map,filename=build/vega-lite.min.js.map -o build/vega-lite.min.js && npm run schema",
    "build:examples": "npm run data && npm run build:only && npm run build:examples-only",
    "build:examples-only": "npm run build:example && npm run build:examples-normalized",
    "build:examples-normalized": "rm -f examples/specs/normalized/*.vl.json && scripts/build-normalized-examples",
    "build:example": "TZ=America/Los_Angeles scripts/build-examples.sh",

    "build:toc": "bundle exec jekyll build -q && scripts/generate-toc",
    "build:site": "browserify site/static/main.ts -p [tsify -p site] -d | exorcist build/site/main.js.map > build/site/main.js",
    "build:versions": "scripts/update-version.sh",
    "build:test-gallery": "browserify test-gallery/main.ts -p [tsify -p test-gallery] -d > build/test-gallery/main.js",
    "check:examples": "scripts/check-examples.sh",
    "check:schema": "scripts/check-schema.sh",
    "clean": "rm -rf build && rm -f vega-lite.* & find -E src test site examples -regex '.*\\.(js|js.map|d.ts)' -delete && rm -f examples/compiled/*.png && find site/examples ! -name 'index.md' -type f -exec rm -f {} +",
    "data": "rsync -r node_modules/vega-datasets/data/* data",
    "link": "npm link && npm link vega-lite",

    "deploy": "scripts/deploy.sh",
    "deploy:gh": "scripts/deploy-gh.sh",
    "deploy:schema": "scripts/deploy-schema.sh",

    "prestart": "npm run data && npm run build && scripts/index-examples",
    "start": "nodemon -x 'npm run build:test-gallery' & browser-sync start --server --files 'build/test-gallery/main.js' --index 'test-gallery/index.html'",
    "poststart": "rm examples/all-examples.json",

    "preschema": "npm run prebuild",
    "schema": "ts-json-schema-generator --path tsconfig.json --type TopLevelExtendedSpec > build/vega-lite-schema.json && npm run renameschema && cp build/vega-lite-schema.json _data/",
    "renameschema": "scripts/rename-schema.sh",
    "presite": "npm run prebuild && npm run data && npm run build:site && npm run build:toc && npm run build:versions && scripts/create-example-pages",
    "site": "bundle exec jekyll serve",

    "lint": "tslint -p .",
    "test": "npm run build:only && npm run test:only && npm run test:runtime && npm run lint",
    "posttest": "npm run schema && npm run data && npm run mocha:examples",
    "test:nocompile": "npm run test:only && npm run test:runtime && npm run lint && npm run mocha:examples",
    "test:only": "nyc --reporter=html --reporter=text-summary npm run mocha:test",
    "test:runtime": "TZ=America/Los_Angeles wdio wdio.conf.js",
    "test:runtime:generate": "rm -Rf test-runtime/resources && VL_GENERATE_TESTS=true npm run test:runtime",
    "test:debug": "npm run tsc && mocha --recursive --debug-brk --inspect build/test",
    "test:debug-examples": "npm run tsc && npm run schema && mocha --recursive --debug-brk --inspect build/examples",
    "mocha:test": "mocha --require source-map-support/register --reporter dot --recursive build/test",
    "mocha:examples": "mocha --require source-map-support/register --reporter dot --recursive build/examples",

    "codecov": "nyc report --reporter=json && codecov -f coverage/*.json",
    "watch:build": "watchify src/index.ts -p tsify -v -d -s vl -o 'exorcist build/vega-lite.js.map > build/vega-lite.js'",
    "watch:tsc": "npm run tsc -- -w",
    "watch:test": "nodemon -x 'npm test'",
    "watch": "nodemon -x 'npm run build && npm run test:nocompile' # already ran schema in build"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/vega/vega-lite.git"
  },
  "license": "BSD-3-Clause",
  "bugs": {
    "url": "https://github.com/vega/vega-lite/issues"
  },
  "devDependencies": {
    "@types/chai": "^4.0.4",
    "@types/d3": "^4.11.0",
    "@types/highlight.js": "^9.1.10",
    "@types/json-stable-stringify": "^1.0.32",
    "@types/mkdirp": "^0.5.1",
    "@types/mocha": "^2.2.43",
    "@types/node": "^8.0.47",
    "@types/webdriverio": "^4.8.6",
    "ajv": "^5.3.0",
    "browser-sync": "^2.18.13",
    "browserify": "^14.5.0",
    "browserify-shim": "^3.8.14",
    "chai": "^4.1.2",
    "cheerio": "^1.0.0-rc.2",
    "chromedriver": "^2.33.2",
    "codecov": "^3.0.0",
    "d3": "^4.11.0",
    "exorcist": "^1.0.0",
    "highlight.js": "^9.12.0",
    "mkdirp": "^0.5.1",
    "mocha": "^4.0.1",
    "nodemon": "^1.12.1",
    "nyc": "^11.2.1",
    "source-map-support": "^0.5.0",
    "svg2png-many": "^0.0.7",
    "ts-json-schema-generator": "^0.14.0",
    "tslint": "5.4.3",
    "ts-node": "^3.3.0",
    "tsify": "^3.0.3",
    "tslint-eslint-rules": "^4.1.1",
    "typescript": "^2.5.3",
    "uglify-js": "^3.1.5",
    "vega": "^3.0.7",
    "vega-datasets": "^1.10.0",
    "vega-embed": "^3.0.0-rc6",
    "vega-tooltip": "^0.4.4",
    "watchify": "^3.9.0",
    "wdio-chromedriver-service": "^0.1.1",
    "wdio-dot-reporter": "0.0.9",
    "wdio-mocha-framework": "^0.5.11",
    "wdio-static-server-service": "^1.0.1",
    "webdriverio": "^4.8.0",
    "yaml-front-matter": "^3.4.0"
  },
  "dependencies": {
    "json-stable-stringify": "^1.0.1",
    "tslib": "^1.8.0",
    "vega-event-selector": "^2.0.0",
    "vega-util": "^1.6.2",
    "yargs": "^10.0.3"
  }
}
